

.about__me {
    width: 100%;
    aspect-ratio: 1/1;
    border-radius: 2rem;
    background: linear-gradient(
        45deg,
        transparent,
        var(--color-primary),
        transparent
    );
    display: grid;
    place-items: center;
}


.about__content  {
    text-align: center;
    margin: 2rem 4rem 4rem;

}

.about__me-image {
    border-radius: 2rem;
    overflow: hidden;
    transform: rotate(10deg);
    transition: var(--transition);
}

.about__me-image:hover {
    transform: rotate(0);
}

/*================ MEDIA QUERIES (MEDIUM DEVICES) ============*/
@media screen and (max-width: 1024px) {

    .about__content p {
        margin: 18rem auto 4rem;
    }
}

/* ================ MEDIA QUERIES (SMALL DEVICES) */
@media screen and (max-width: 600px) {


  .about__content {
    text-align: center;
    margin: 1rem 1rem 1.5rem;
  }

  .about__content p {
    
    margin-top: -2.5rem;
  }

}

/*   

    .about__me {
        width: 50%;
        margin: 0rem auto 4rem;
    }


.about__me {
    width: 65%;
    margin: 0 auto 3rem;
    

  } */