header {
    height: 100vh;
    padding-top: 2rem;
    overflow: hidden;
    text-align: center;
}

.header__container {
    text-align: center;
    height: 100%;
    position: relative;
}

/*==========CTA=============== */
.cta {
    margin-top: 2.5rem;
    display: flex;
    gap: 1.3rem;
    justify-content: center ; 
}

/*========== Socials =============== */

.header__socials::before {
    content: "";
    width: 2px;
    height: 2rem;
    background: var(--color-primary);
}

.header__socials {

    display:flex;
    justify-content: center;
    gap: 2rem;
    margin-top: 2rem;
    left: 0rem;
    bottom: 3rem;
}

.header__socials::after {
    content: "";
    width: 2px;
    height: 2rem;
    background: var(--color-primary);
}


/*========== Image =============== */
.me {
    background: linear-gradient(var(--color-primary), transparent);
    width: 22rem;
    height: 30rem;
    position:absolute;
    left: calc(50% - 11rem);
    margin-top: 1rem;
    border-radius: 12rem 12rem 0 0;
    overflow: hidden;
    padding: 6rem 2rem 1.5rem 1.5rem;
}

/*========== Scroll Down =============== */
    .scroll__down {
        position: absolute;
        right: -2rem;
        bottom: 5rem;
        transform: rotate(90deg);
        font-weight: 200;
        font-size: 0.9rem;
    }

    /*================ MEDIA QUERIES (MEDIUM DEVICES) ============*/
@media screen and (max-width: 1024px) {
    header {
    height: 68vh;
    }
    }
    
    /* ================ MEDIA QUERIES (SMALL DEVICES) */
    @media screen and (max-width: 600px) {
    header{
    height: 100vh;
    }
    }